'use client';
import React, { PropsWithChildren } from 'react';
import { Navigation } from '../navigation/Navigation';
import { MobileSidebar } from '../MobileSidebar/MobileSidebar';
import { Footer } from '../footer/Footer';

export interface IBaseLayout extends PropsWithChildren {}

export const BaseLayout: React.FC<IBaseLayout> = (props) => {
    const { children } = props;

    return (
        <div className="relative flex min-h-screen w-screen flex-col overflow-x-hidden bg-neutral-900 pb-48">
            <Navigation />
            <MobileSidebar />
            <div className="mx-auto w-full">{children}</div>
            <Footer />
        </div>
    );
};
