import React, { useState } from 'react';

export interface ISwipeToClose {
    setOpen: (open: boolean) => void;
    children: React.ReactNode;
}

export const SwipeToClose: React.FC<ISwipeToClose> = (props) => {
    const { children, setOpen } = props;

    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);

    function handleTouchStart(e: React.TouchEvent) {
        setTouchStart(e.targetTouches[0]?.clientX ?? 0);
        setTouchEnd(null); // Reset the touch end
    }

    function handleTouchMove(e: React.TouchEvent) {
        setTouchEnd(e.targetTouches[0]?.clientX ?? 0);
    }

    function handleTouchEnd() {
        if (touchStart !== null && touchEnd !== null) {
            const touchDifference = touchStart - touchEnd;

            if (touchDifference > 100) {
                // a left swipe
                setOpen(false);
            }
        }
    }

    return (
        <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </div>
    );
};
