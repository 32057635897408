import React, { PropsWithChildren, useRef } from 'react';
import Link from 'next/link';
import Ripples from 'react-ripples';

export interface IRippleButton extends PropsWithChildren {
    onClick?: () => void;
    href?: string;
    target?: string;
    wrapperCss?: string;
    disabled?: boolean;
    testId?: string;
}

export const RippleButton: React.FC<IRippleButton> = (props) => {
    const { children, wrapperCss, onClick, href, target, disabled, testId } = props;

    const linkRef = useRef<HTMLAnchorElement | null>(null);

    return (
        <Ripples
            color={'rgba(0, 68, 139, 0.5)'}
            during={500}
            className={`relative w-full overflow-hidden ${wrapperCss ?? ''}`}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <div className="relative flex w-full flex-col gap-0">
                {onClick && !href && (
                    <button
                        className=""
                        onClick={() => {
                            setTimeout(() => {
                                if (onClick) onClick();
                            }, 150);
                        }}
                        role="button"
                        disabled={disabled}
                        aria-disabled={disabled}
                        data-test-id={`${testId ?? ''}-button`}
                    >
                        {children}
                    </button>
                )}

                {href && (
                    <Link
                        href={href}
                        onClick={() => {
                            setTimeout(() => {
                                if (linkRef.current) {
                                    linkRef.current.click();
                                }
                            }, 150);
                        }}
                        target={target}
                        data-test-id={`${testId ?? ''}-button`}
                    >
                        {children}
                    </Link>
                )}
            </div>

            <a href={href} target={target} ref={linkRef} />
        </Ripples>
    );
};
