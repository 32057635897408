'use client';
import React from 'react';
import { SwipeToClose } from '../SwipeElement/SwiperToClose';
import { Transition } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../button/IconButton';
import { useSidebarState } from './state/sidebar.state';
import { SidebarItem } from './elements/SidebarItem';
import { LogoCloudbar } from '../logoCloudbar/LogoCloudbar';
import { SidebarItemSmall } from './elements/SidebarItemSmall';

interface IMobileSidebar {}

export const MobileSidebar: React.FC<IMobileSidebar> = () => {
    const { setOpen, open } = useSidebarState();

    return (
        <SwipeToClose
            setOpen={() => {
                setOpen(false);
            }}
        >
            {/* TODO Swiper left to close (setOpen=false) */}
            <Transition
                as={'div'}
                className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-end bg-neutral-900/70 backdrop-blur-md"
                show={open}
                appear={open}
                enter="transition ease-out duration-500"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
            >
                <ChevronDoubleLeftIcon className="relative right-3 flex h-8 text-neutral-500/50 md:h-14" />
            </Transition>
            <Transition
                show={open}
                appear={open}
                className="absolute left-0 top-0 z-50 flex h-screen w-full flex-col"
                enter="transition transform ease-in-out duration-400"
                enterFrom="opacity-0 -translate-x-[300px]"
                enterTo="opacity-100 -translate-x-0"
                leave="transition transform ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 -translate-x-[300px]"
            >
                {/* TODO Sidebar Element */}

                <div className="absolute flex w-full justify-end px-4 py-6">
                    <IconButton
                        wrapperCss="relative w-min shrink"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <ChevronLeftIcon className="h-6 text-neutral-50" />
                    </IconButton>
                </div>

                <nav className="relative flex w-10/12 flex-col gap-4 px-8 py-8">
                    <SidebarItem label={'Home'} href={'/'} />
                    <SidebarItem label={'Pricing'} href={'/pricing'} />
                    <div className="block h-12 w-[40%] border-b border-neutral-500" />
                    <SidebarItemSmall label={'Legal'} href={'/legal'} />
                    <SidebarItemSmall label={'Privacy'} href={'/privacy'} />
                </nav>

                <div className="absolute bottom-0 flex w-full items-center justify-center p-2">
                    <LogoCloudbar />
                </div>
            </Transition>
        </SwipeToClose>
    );
};
