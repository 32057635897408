import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import Link from 'next/link';
import { nanoid } from 'nanoid';
import Ripples from 'react-ripples';

export interface IIconButton extends PropsWithChildren {
    onClick?: () => void;
    href?: string;
    target?: string;
    additionalCss?: string;
    outerCss?: string;
    wrapperCss?: string;
    labelCss?: string;
    iconSpin?: boolean;
    icon?: ReactElement<unknown>;
    disabled?: boolean;
    testId?: string;
    tooltip?: JSX.Element | string;
    tooltipDirection?: 'left' | 'right';
    preventDefault?: boolean;
}

export const IconButton: React.FC<IIconButton> = (props) => {
    const {
        additionalCss,
        wrapperCss,
        labelCss,
        outerCss,
        onClick,
        children,
        href,
        target = '_self',
        disabled,
        iconSpin = false,
        icon,
        testId,
        tooltip,
        tooltipDirection,
        preventDefault,
    } = props;

    const iconSpinStyle = useMemo((): string => {
        return iconSpin ? 'animate-spin' : '';
    }, [iconSpin]);

    const buttonStyles = useMemo((): string => {
        return 'w-7 h-7 aspect-[1/1] flex justify-center items-center flex';
    }, []);

    return (
        <div className={`${outerCss ?? ''} group relative`}>
            <Ripples
                color={'rgba(0, 68, 139, 0.2)'}
                during={500}
                className={`relative h-max ${
                    wrapperCss ?? ''
                } aspect-[1/1] overflow-hidden rounded-full border border-neutral-700 bg-neutral-800 opacity-50 transition-all hover:opacity-100`}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                <div className={`relative flex w-full flex-col gap-0 `}>
                    {onClick && !href && (
                        <button
                            onClick={(event) => {
                                if (preventDefault) {
                                    event.preventDefault();
                                }

                                setTimeout(() => {
                                    if (onClick) onClick();
                                }, 150);
                            }}
                            role="button"
                            disabled={disabled}
                            aria-disabled={disabled}
                            data-test-id={`${testId ?? nanoid()}-button`}
                            className={`relative z-20 flex h-full w-full flex-row items-center rounded p-1 font-semibold tracking-wide ${
                                additionalCss ?? ''
                            }
                     ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        >
                            <div className={`${iconSpinStyle} ${buttonStyles}`}>
                                {icon && (
                                    <span className={`h-5 text-text-light ${labelCss ?? ''}`}>
                                        {icon}
                                    </span>
                                )}
                                {children}
                            </div>
                        </button>
                    )}

                    {href && (
                        <Link
                            href={href}
                            onClick={onClick}
                            target={target}
                            data-test-id={`${testId ?? nanoid()}-linkButton`}
                            className={`relative z-20 flex h-full w-full items-center justify-center rounded p-1 ${
                                additionalCss ?? ''
                            }`}
                        >
                            <div className={`${iconSpinStyle} ${buttonStyles} ${labelCss ?? ''}`}>
                                {children}
                            </div>
                        </Link>
                    )}
                </div>
            </Ripples>

            {tooltip && (
                <div
                    className={`absolute top-10 z-20 hidden h-0 w-max max-w-[200px] rounded border border-neutral-200 bg-white text-xs shadow transition-all group-hover:flex group-hover:h-max ${
                        tooltipDirection === 'left' ? 'right-0' : 'left-0'
                    }`}
                >
                    <div className="hidden flex-row p-2 group-hover:flex">{tooltip}</div>
                </div>
            )}
        </div>
    );
};
