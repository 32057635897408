import { create } from 'zustand';

interface ISidebarState {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const useSidebarState = create<ISidebarState>((set) => ({
    open: false,
    setOpen: (open) => {
        set({ open });
    },
}));
