'use client';
import React from 'react';
import { LogoCloudbar } from '../logoCloudbar/LogoCloudbar';
import { Button } from '../button/Button';
import { NavigationItem } from './elements/NavigationItem';
import Link from 'next/link';
import { IconButton } from '../button/IconButton';
import { Bars4Icon } from '@heroicons/react/24/solid';
import { useSidebarState } from '../MobileSidebar/state/sidebar.state';

interface INavigation {}

export const Navigation: React.FC<INavigation> = () => {
    const { setOpen, open } = useSidebarState();

    return (
        <nav className="sticky top-0 z-30 flex h-20 w-full items-center justify-center bg-neutral-900/20 px-4 backdrop-blur-2xl md:px-4 2xl:px-0">
            <div className="h-full w-full max-w-7xl">
                <div className="flex h-full flex-row items-center justify-between">
                    <Link href="/">
                        <LogoCloudbar />
                    </Link>

                    {/* Desktop Navigation */}
                    <div className="relative hidden w-max flex-row justify-between gap-10 md:flex">
                        <NavigationItem label="Home" href="/" />
                        <NavigationItem label="Pricing" href="/pricing" />
                    </div>
                    <div>
                        <Button href="/pricing">Get started</Button>
                    </div>

                    <IconButton
                        outerCss="flex md:hidden"
                        onClick={() => {
                            setOpen(!open);
                        }}
                        icon={<Bars4Icon className="h-5 text-neutral-50" />}
                    />
                </div>
            </div>
        </nav>
    );
};
