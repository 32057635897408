import React from 'react';
import { RippleButton } from '../../button/RippleButton';
import { useSidebarState } from '../state/sidebar.state';

export interface ISidebarItem {
    href: string;
    label: string;
}

export const SidebarItem: React.FC<ISidebarItem> = (props) => {
    const { label, href } = props;

    const { setOpen } = useSidebarState();

    return (
        <RippleButton
            href={href}
            wrapperCss="group"
            onClick={() => {
                setOpen(false);
            }}
        >
            <span className="font-bold text-xl text-neutral-50">{label}</span>
            <div className="gradient-cb3-cymk block h-[2px] w-full opacity-0 transition-all duration-300 group-hover:opacity-100" />
        </RippleButton>
    );
};
